<template>
<ion-page>
  <ion-header>
    <app-toolbar :title="$t('interface.home')"/>
  </ion-header>

  <ion-content>
    <ion-grid>
      <ion-row>
        <ion-col size="12" size-md="6">
          <product-card
              color="#E5BB25"
              :image="require('@/assets/png/driving.png')"
              :isLocked="true"
              :title="$t('Cockpit')"
              :subtitle="$t('CockpitDescription')"
              :text="$t('CockpitText')"
              :action="showLoginActionSheet"
          />
        </ion-col>
        <ion-col size="12" size-md="6">
          <product-card
              color="#005288"
              :image="require('@/assets/png/clicklearn_icon.png')"
              :isLocked="true"
              :title="$t('ClickAndLearn')"
              :subtitle="$t('ClickAndLearnDescription')"
              :text="$t('ClickAndLearnText')"
              :action="showLoginActionSheet"
          />
        </ion-col>
        <ion-col v-if="false" size="12" size-md="6" size-lg="4">
          <product-card
            color="#560f20"
            :image="require('@/assets/png/elearning_icon.png')"
            :isLocked="true"
            :title="$t('eLearning')"
            :subtitle="$t('eLearningTitle')"
            :text="$t('eLearningDescription')"
            :action="showLoginActionSheet"
          />
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
  <app-footer/>
</ion-page>
</template>

<script lang="js">
import {Auth} from '@/services/AuthService';
import AppToolbar from '@/components/app-structure/AppToolbar.vue';
import AppFooter from "@/components/app-structure/AppFooter.vue";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonGrid,
  IonRow,
  IonCol,
  actionSheetController
} from '@ionic/vue';
import ProductCard from '@/components/cards/ProductCard.vue'
import {AuthActions} from 'ionic-appauth';
import {defineComponent} from 'vue';
import { lockClosed, ellipsisHorizontal, ellipsisVertical, calendar, personCircle, cogOutline } from 'ionicons/icons';
import {useI18n} from "vue-i18n";
import store from "@/store";

export default defineComponent({
  name: 'LandingPage',
  data() {
    return {
      event: '',
    };
  },
  setup() {
    const { t }  = useI18n()

    const showLoginActionSheet = async () => {
      const actionSheet = await actionSheetController.create({
          header: t('loginWith'),
          buttons: [
              { text: t('usernameOrPassword'), handler: () => Auth.Instance.signIn()},
              { text: t('qrCode'), handler: () => {
                  store.commit('showQRScanner', {title: t('loginWith'), type: 'login-with'})
              }}
          ],
      });

      await actionSheet.present();
    };

    return {
      lockClosed, ellipsisHorizontal, ellipsisVertical, calendar, personCircle, cogOutline, showLoginActionSheet
    }
  },
  created() {
    Auth.Instance.events$.subscribe((action) => {
      this.event = JSON.stringify(action);
      if (action.action === AuthActions.SignInSuccess) {
        Auth.Instance.loadUserInfo()
        this.$router.push('/home');
      }
    });
  },
  methods: {
    signIn() {
      Auth.Instance.signIn();
    },
    throwNewException() {
      throw new Error('Error exception from android application when there is no logged in user')
    }
  },
  components: {
    IonContent,
    IonPage,
    IonHeader,
    AppToolbar,
    AppFooter,
    ProductCard,
    IonGrid,
    IonRow,
    IonCol,
  }
});
</script>
