import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_menu = _resolveComponent("app-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!
  const _component_q_r_code_scanner_modal = _resolveComponent("q-r-code-scanner-modal")!
  const _component_help_modal = _resolveComponent("help-modal")!
  const _component_ion_app = _resolveComponent("ion-app")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toast = _resolveComponent("ion-toast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_app, null, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_ion_split_pane, {
          disabled: _ctx.splitPaneDisabled,
          "content-id": "main-content",
          onIonSplitPaneVisible: _ctx.visibleStateChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_menu, { splitPaneVisible: _ctx.splitPaneVisible }, null, 8, ["splitPaneVisible"]),
            _createVNode(_component_ion_router_outlet, { id: "main-content" })
          ]),
          _: 1
        }, 8, ["disabled", "onIonSplitPaneVisible"]), [
          [_vShow, !_ctx.isScannerRunning]
        ]),
        _createVNode(_component_q_r_code_scanner_modal, {
          ref: "qrcodeModal",
          title: _ctx.qrScanner.title,
          type: _ctx.qrScanner.type,
          onStart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isScannerRunning = true)),
          onStop: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isScannerRunning = false))
        }, null, 8, ["title", "type"]),
        _createVNode(_component_help_modal, { ref: "helpModal" }, null, 512)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_button, {
      id: "gohome",
      "router-direction": "back",
      "router-link": "/",
      style: {"display":"none"}
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode("Home")
      ])),
      _: 1
    }),
    _createVNode(_component_ion_toast, {
      buttons: _ctx.toastButtons,
      duration: _ctx.toastMessage.duration,
      header: _ctx.toastMessage.title,
      "is-open": _ctx.toastMessage.show,
      message: _ctx.toastMessage.message,
      position: "top"
    }, null, 8, ["buttons", "duration", "header", "is-open", "message"])
  ], 64))
}