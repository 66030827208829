<template>
  <div style="display: inline-flex; align-items: center; align-self: center; width: auto">
    <ion-img router-link="/" router-direction="root" :src="iconImage"  style="height: 32px; width: 32px; margin-right: 10px"/>
    {{ title }}
  </div>

</template>

<script lang="ts">
import {defineComponent} from 'vue';
import { IonImg } from "@ionic/vue";

export default defineComponent({
  name: 'PageTitle',
  components: { IonImg },
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    iconImage () {
      // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      // const theme = prefersDark.matches ? 'dark' : 'light';
      return new URL('@/assets/png/campus_icon.png', import.meta.url).href;
      // return require(`@/assets/svg/logo-theme-${theme}.svg`)
    }
  }
});
</script>
