<template>
    <ion-app>
        <ion-split-pane v-show="!isScannerRunning" :disabled="splitPaneDisabled" content-id="main-content" @ionSplitPaneVisible="visibleStateChange">
          <!--  the side menu  -->
          <app-menu :splitPaneVisible="splitPaneVisible"/>
          <!-- the main content -->
          <ion-router-outlet id="main-content"/>
        </ion-split-pane>

        <q-r-code-scanner-modal
          ref="qrcodeModal"
          :title="qrScanner.title"
          :type="qrScanner.type"
          @start="isScannerRunning = true"
          @stop="isScannerRunning = false"
        />
        <help-modal ref="helpModal"/>
    </ion-app>

    <ion-button id="gohome" router-direction="back" router-link="/" style="display: none;">Home</ion-button>

    <ion-toast
      :buttons="toastButtons"
      :duration="toastMessage.duration"
      :header="toastMessage.title"
      :is-open="toastMessage.show"
      :message="toastMessage.message"
      position="top"
    />
</template>

<script lang="ts">
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  IonButton,
  IonToast,
  useBackButton,
  useIonRouter,
  isPlatform,
} from '@ionic/vue';

import {defineComponent, computed, ref, watch, getCurrentInstance, onMounted, onUnmounted} from 'vue';
import {Auth} from '@/services/AuthService'
import {filter, switchMap, take} from 'rxjs/operators';
import {useStore} from 'vuex';
import {AuthActions} from "ionic-appauth";
import {Subscription} from "rxjs";
import {useRouter} from "vue-router";
import AppMenu from "@/components/app-structure/AppMenu.vue";
import {App} from "@capacitor/app";
import PushNotificationActionHandler from "@/helpers/push/PushNotificationActionHandler";
import {Keyboard} from '@capacitor/keyboard'
import QRCodeScannerModal from "@/components/QRCodeScannerModal.vue";
import HelpModal from "@/components/HelpModal.vue";
import * as Sentry from '@sentry/capacitor';
import * as SentryVue from "@sentry/vue";
import {jwtDecode} from "jwt-decode";
import {JwtPayload} from "@/types/auth";

export default defineComponent({
    name: 'App',
    setup() {
        const store = useStore();
        const router = useRouter();
        const subs = [] as Subscription[];
        const splitPaneVisible = ref(false)
        const isScannerRunning = ref(false);
        const splitPaneDisabled = computed(() => {
            const routeName = String(router.currentRoute.value.name)
            const disabledOnRoutes = ['CampusApp', 'ClickAndLearnApp', 'ELearningAppPage']
            return disabledOnRoutes.includes(routeName)
        })
        const visibleStateChange = (e: any) => {
          splitPaneVisible.value = e.detail.visible
        }

        const checkIfSentryIsAllowedToLog = () => {
          const tokenResponse = localStorage.getItem('token_response')

          if (! tokenResponse) return false

          const token = tokenResponse ? JSON.parse(tokenResponse).access_token : ''
          const decodedJWTToken: JwtPayload = jwtDecode(token)

          return decodedJWTToken.SentryTracing === 'true'
        }
        const closeSentry = () => {
          Sentry.setUser(null)
          Sentry.getCurrentScope().getClient()?.close()
        }

        const initSentry = () => {
          if (Sentry.getCurrentScope().getClient()) return false

          Sentry.init({
                app: getCurrentInstance()?.appContext.app,
                dsn: process.env.VUE_APP_SENTRY_DNS,
                environment: process.env.NODE_ENV === 'production' ? 'production' : 'development',
                integrations: [
                  Sentry.replayIntegration(),
                  SentryVue.captureConsoleIntegration({levels: ['error']})
                ],
                replaysSessionSampleRate: 1,
                replaysOnErrorSampleRate: 1,
                debug: process.env.VUE_APP_SENTRY_DEBUG === 'true',
                beforeSend: (event) =>  checkIfSentryIsAllowedToLog() ? event : null,
                beforeSendTransaction: (event) => checkIfSentryIsAllowedToLog() ? event : null,
                beforeBreadcrumb: (event) => checkIfSentryIsAllowedToLog() ? event : null
              }
          );
        }

        Auth.Instance.initComplete$.pipe(
            filter(complete => complete),
            switchMap(() => Auth.Instance.isAuthenticated$),
            take(1)
        ).subscribe((isAuthenticated) => {
            if (isAuthenticated) Auth.tryRefreshingTheToken()
        });

        subs.push(
            Auth.Instance.events$.subscribe((action) => {
              switch (action.action) {
                case AuthActions.SignOutSuccess:
                  Auth.clearUserData()
                  router.push('/');
                  break;
                case AuthActions.RefreshSuccess:
                  setToken()
                  break;
                case AuthActions.RefreshFailed:
                  Auth.clearUserData()
                  router.push({ name: 'Landing' })
                  break;
                case AuthActions.LoadUserInfoSuccess: {
                  const cloudAPIURL = action.user.CloudApiUri || null
                  store.commit('setUserInfo',{
                    name: action.user.name,
                    username: action.user.preferred_username,
                    email: action.user.email,
                    cloudApiUrl: cloudAPIURL,
                  })
                  if (cloudAPIURL) {
                    localStorage.setItem('cloudApiUrl', cloudAPIURL)
                    store.dispatch('loadSchoolInfo')
                  } else {
                    localStorage.removeItem('cloudApiUrl')
                    store.commit('setSchoolLoadingStatus', false)
                  }
                  Sentry.setUser({ name: action.user.name })
                }
              }
            }),
            Auth.Instance.isAuthenticated$.subscribe(state => {
                store.commit('setAuthState', state);
            }),
        )

        const setToken = () => {
            const webviewIframeEl = document.getElementById('webviewIframe') as HTMLIFrameElement;
            const tokenResponse = localStorage.getItem('token_response') ?? ''

            webviewIframeEl?.contentWindow?.postMessage({
                action: 'setToken',
                tokenResponse: JSON.parse(tokenResponse)
            }, "*");
        }

        const ionRouter = useIonRouter();

        useBackButton(-1, () => {
            if (!ionRouter.canGoBack()) App.exitApp();
        })

        const toastMessage = computed(() => store.state.toastMessage)
        const toastButtons = [{
            text: 'Öffnen',
            handler: () => {
              if (toastMessage.value.action && toastMessage.value.args) {
                  const args = JSON.parse(toastMessage.value.args)
                  const handler = new PushNotificationActionHandler(toastMessage.value.action, args);
                  handler.run();
              }
            }
        }]

        const qrScanner = computed(() => store.state.QRScanner)
        const qrcodeModal = ref<typeof QRCodeScannerModal | null>(null)
        watch(() => store.state.QRScanner.show, async (value) => {
          if (value) {
            qrcodeModal.value?.open()
          } else {
            qrcodeModal.value?.close()
          }
        })

        const helpModal = ref<typeof HelpModal | null>(null)
        watch(() => store.state.showHelpModal, async (value) => {
          if (value) {
            helpModal.value?.open()
          } else {
            helpModal.value?.close()
          }
        })

        onMounted(() => {
          initSentry()
        })

        onUnmounted(() => {
          closeSentry()
        })

        return {
            store,
            subs,
            splitPaneDisabled,
            splitPaneVisible,
            visibleStateChange,
            toastMessage,
            toastButtons,
            qrScanner,
            isScannerRunning,
            qrcodeModal,
            helpModal
        };
    },
    mounted() {
      if (isPlatform('capacitor')) {
          Keyboard.addListener('keyboardWillShow', () => {
              const webviewIframeEl = document.getElementById('webviewIframe') as HTMLIFrameElement;
              const contentWindow = webviewIframeEl?.contentWindow

              if (contentWindow !== null) {
                  contentWindow.postMessage({action: 'keyboardWillShow'}, '*')
              }
          })
      }
    },
    beforeUnmount() {
      this.subs.forEach(sub => sub.unsubscribe())
    },
    computed: {
      layout() {
          return this.$route.meta.layout || 'layout-main'
      }
    },
    components: {
        QRCodeScannerModal,
        AppMenu,
        IonApp,
        IonRouterOutlet,
        IonSplitPane,
        IonButton,
        IonToast,
        HelpModal
    }
});
</script>

<style>
ion-split-pane {
    --side-width: 350px;
    --side-max-width: 350px;
}

ion-toast::part(button) {
    color: var(--color);
}
</style>
