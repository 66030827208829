<template>
  <ion-modal :is-open="isQRCodeModalOpen" @willDismiss="onWillDismiss">
    <ion-header>
      <ion-toolbar>
        <ion-title>{{title}}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="close" color="dark">
            <ion-icon slot="icon-only" :icon="closeOutline"/>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="content-result">
        <the-loader v-if="loading"/>

        <template v-else>
          <template v-if="qrCodeSuccessfullyScanned">
            <ion-icon slot="end" :md="checkmarkOutline" :ios="checkmarkOutline" style="font-size: 70px;" color="success"></ion-icon>
            <ion-text style="margin-top: 5px;">
              <h5>{{ $t('qrCodeScannedSuccessfully') }}</h5>
            </ion-text>
          </template>

          <div class="error-message" v-else-if="theoryAttendanceError.length > 0">
            <ion-icon slot="end" :md="closeOutline" :ios="closeOutline" style="font-size: 70px;"></ion-icon>
            <ion-text style="margin-top: 5px;text-align: center;">
              <h5>{{ theoryAttendanceError }}</h5>
            </ion-text>
            <ion-button expand="block" @click="startScan">{{ $t('interface.scanQRCode') }}</ion-button>
          </div>

          <div class="error-message" v-else>
            <ion-icon slot="end" :md="qrCodeOutline" :ios="qrCodeOutline" style="font-size: 70px;"></ion-icon>
            <ion-text style="margin-top: 5px;text-align: center;">
              <h5>{{ statusMessage }}</h5>
            </ion-text>
            <ion-button expand="block" @click="startScan">{{ $t('interface.scanQRCode') }}</ion-button>
          </div>
        </template>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script lang="js">
import api from "../services/Api";

import { useI18n } from 'vue-i18n'
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';

import TheLoader from "@/components/TheLoader.vue";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonText,
  IonIcon,
  IonContent,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import {CapacitorBarcodeScanner, CapacitorBarcodeScannerTypeHint} from "@capacitor/barcode-scanner";
import { checkmarkOutline, closeOutline, qrCodeOutline, cameraReverseOutline } from "ionicons/icons";

import {Auth} from "@/services/AuthService";
import store from "@/store";

export default defineComponent({
  emits: ['start', 'stop'],
  setup(props, { emit }) {
    const { t }  = useI18n()
    const loading = ref(false);
    const isQRCodeModalOpen = ref(false);
    const qrCodeSuccessfullyScanned = ref(false);
    const theoryAttendanceError = ref('');

    const startScan = async () => {
      resetDialog()
      emit('start')

      const result = await CapacitorBarcodeScanner.scanBarcode({
        hint:  CapacitorBarcodeScannerTypeHint.QR_CODE
      });

      // if the result has content
      if (result.ScanResult) {
        stopScan();
        if (props.type === 'login-with') {
          let username = null;
          let password = null;

          try {
            const url = new URL(result.ScanResult);
            const searchParams = new URLSearchParams(url.search);

            if (!searchParams.get('username') || !searchParams.get('password')) {
              throw new Error('No username or password provided');
            }

            username = searchParams.get('username')
            password = searchParams.get('password')
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e.message)
            theoryAttendanceError.value = t('messages.invalidTokenProvided')
            return
          }

          try {
            await Auth.Instance.signIn({ username, password })
          } catch (e) {
            theoryAttendanceError.value = e.message
          }

          close()
        }

        if (props.type === 'theory-attendance') {
          loading.value = true
          api
              .post('lessonattendance', { qrCodeGuid: result.ScanResult })
              .then(() => {
                qrCodeSuccessfullyScanned.value = true
              })
              .catch(error => {
                const response = error.response || {}
                const responseData = response.data || {}

                let errorMessage = responseData.message ?? ''
                if (responseData.errors) {
                  const errorsKeys = Object.keys(responseData.errors).reverse()
                  errorMessage = responseData.errors[errorsKeys[0]] ? responseData.errors[errorsKeys[0]][0] : ''
                }

                theoryAttendanceError.value = errorMessage || t('messages.somethingWentWrong')
              })
              .finally(() => {
                loading.value = false
              })
        }
      }
    };

    const stopScan = () => {
      emit('stop')
    };

    const resetDialog = () => {
      theoryAttendanceError.value = ''
      qrCodeSuccessfullyScanned.value = false
    }

    const open = () => {
      isQRCodeModalOpen.value = true

      resetDialog()
      startScan()
    }

    const close = () => {
      isQRCodeModalOpen.value = false
      store.commit('closeQRScanner')

      stopScan()
    }

    const onWillDismiss = () => close()

    onMounted(() => {
      document.addEventListener('ionBackButton', close);
    })

    onUnmounted(() => {
      document.removeEventListener('ionBackButton', close);
    })

    return {
      isQRCodeModalOpen,
      loading,
      theoryAttendanceError,
      qrCodeSuccessfullyScanned,
      startScan,
      stopScan,
      open,
      close,
      checkmarkOutline,
      closeOutline,
      qrCodeOutline,
      cameraReverseOutline,
      onWillDismiss
    }
  },
  name: "QRCodeScannerModal",
  components: {
    TheLoader,
    IonModal,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonText,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'theory-attendance'
    }
  },
})
</script>

<style scoped lang="scss">
.modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center
}
.content-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 10%;
}
.error-message {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scan-box {
  border: 1px solid #fff;
  border-radius: 10%;
  box-shadow: 0 0 0 100vmax rgb(0, 0, 0, 0.5);
  content: "";
  display: block;
  left: 50%;
  height: 300px;
  position: absolute;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 300px;
  z-index: 1;
}
.action-buttons {
  position: absolute;
  padding: 0 10px;
  width: 100%;
  z-index: 2;
  bottom: 10px;
}
</style>
