<template>
  <ion-page>
    <ion-header>
      <app-toolbar :title="$t('interface.dashboard')" />
    </ion-header>

    <ion-content class="ion-align-items-center">
      <div class="center-content" v-if="schoolLoading">
        <TheLoader/>
      </div>

      <ion-grid v-else>
        <ion-row>
          <ion-col v-if="showSchoolCard" size="12" size-md="6" class="ion-no-padding">
            <ion-row>
              <ion-col size="12">
                <product-card
                    color="#c51824"
                    :image="require('@/assets/png/school-main.png')"
                    :title="school.name"
                    :subtitle="$t('DeineFahrschule')"
                    :has-main-action="false"
                    :email="school.email"
                    :website="school.website"
                    :phone="school.phone"
                    :facebook="school.facebook"
                    :instagram="school.instagram"
                    :youtube="school.youtube"
                    :tiktok="school.tikTok"
                    :snapchat="school.snapChat"
                    :action="()=>{}"
                >
                  <template v-slot:description-image v-if="formattedSchoolLogo">
                    <div class="logo-wrapper">
                      <img class="logo" :src="formattedSchoolLogo" :alt="school.name">
                    </div>
                  </template>
                  <template v-slot:text>
                    <span>{{ school.street }}</span><br>
                    <span>{{ school.zipCode }} {{ school.city }}</span>
                  </template>
                </product-card>
              </ion-col>
              <ion-col size="12" v-if="inLandscapeOrientation">
                <product-card
                  color="#005288"
                  :image="require('@/assets/png/clicklearn_icon.png')"
                  :title="$t('ClickAndLearn')"
                  :subtitle="$t('ClickAndLearnDescription')"
                  :text="$t('ClickAndLearnText')"
                  :action="openClickAndLearn"
                  :has-settings="false"
                  settings-url="/preferences/click-and-learn"
              />
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col size="12" size-md="6">
            <product-card
                color="#E5BB25"
                :image="require('@/assets/png/cockpit.png')"
                :title="$t('Cockpit')"
                :subtitle="$t('CockpitDescription')"
                :text="$t('CockpitText')"
                :action="openCampus"
                :has-settings="false"
                settings-url="/preferences/campus-central"
            />
          </ion-col>
          <ion-col size="12" size-md="6" v-if="!inLandscapeOrientation">
            <product-card
                color="#005288"
                :image="require('@/assets/png/clicklearn_icon.png')"
                :title="$t('ClickAndLearn')"
                :subtitle="$t('ClickAndLearnDescription')"
                :text="$t('ClickAndLearnText')"
                :action="openClickAndLearn"
                :has-settings="false"
                settings-url="/preferences/click-and-learn"
            />
          </ion-col>
          <ion-col v-if="false" size="12" size-md="6" size-lg="4">
            <product-card
              color="#560f20"
              :image="require('@/assets/png/elearning_icon.png')"
              :title="$t('eLearning')"
              :subtitle="$t('eLearningTitle')"
              :text="$t('eLearningDescription')"
              :action="openELearning"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
      <input id="pushnotificationstoken" value="" style="width: 100%; display: none" readonly/>
      <a href="https://click-learn.info" target="_blank" style="display: none;" id="clickAndLearnLink">ClickLearn</a>
    </ion-content>

    <app-footer/>

    <suspense>
      <PushNotificationInit />
    </suspense>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonCol,
  IonGrid
} from '@ionic/vue';

import {computed, defineComponent} from 'vue';
import { useRouter } from 'vue-router';
import {Auth} from '@/services/AuthService';
import {AuthActions} from 'ionic-appauth';
import {Subscription} from 'rxjs';
import { settings } from 'ionicons/icons';
import AppToolbar from '@/components/app-structure/AppToolbar.vue';
import ProductCard from '@/components/cards/ProductCard.vue'
import AppFooter from "@/components/app-structure/AppFooter.vue";
import PushNotificationInit from "@/components/PushNotificationInit.vue";
import { AppLauncher } from '@capacitor/app-launcher';
import { Device } from '@capacitor/device';
import {useStore} from "vuex";
import TheLoader from "@/components/TheLoader.vue";
import {useOrientation} from "@/composables/useOrientation";

export default defineComponent({
  name: 'HomePage',
  setup () {
    const router = useRouter();
    const openCampus = () => router.push({ name: 'CampusApp' })

    const openClickAndLearn = async () => {
      const { platform } = await Device.getInfo();

      if (platform === 'android') {
        const { value: canOpenUrl } = await AppLauncher.canOpenUrl({ url: 'de.degener.clicklearn.forms' });

        if (canOpenUrl) {
          await AppLauncher.openUrl({ url: 'de.degener.clicklearn.forms' });
          return;
        }
      }

      const link = document.getElementById('clickAndLearnLink');
      link?.click()
      return;
    };

    const openELearning = () => router.push({ name: 'ELearningAppPage' })

    const store = useStore();
    const schoolLoading = computed(() => store.state.schoolLoading);
    const school = computed(() => store.state.school);
    const showSchoolCard = computed(() => {
      return (school.value.name + school.value.street + school.value.zipCode + school.value.city).length > 0
    })

    const isValidBase64 = (str: string) => /^[A-Za-z0-9+/]+={0,2}$/.test(str);
    const formattedSchoolLogo = computed(() => isValidBase64(school.value.logo) ? `data:image/png;base64,${school.value.logo}` : school.value.logo);

    const { orientation } = useOrientation()
    const inLandscapeOrientation = computed(() => orientation.value.includes('landscape'))

    const throwNewException = () => {
      throw new Error('Test error exception from android application when user is logged in: ' + store.state.user.username)
    }

    return { settings, openCampus, openClickAndLearn, openELearning, showSchoolCard, schoolLoading, school, formattedSchoolLogo, inLandscapeOrientation, throwNewException }
  },
  data() {
    return {
      event: '',
      user: '',
      subs: [] as Subscription[]
    };
  },
  created() {
    Auth.Instance.loadUserInfo()
    this.subs.push(
      Auth.Instance.events$.subscribe((action) => {
        this.event = JSON.stringify(action);
        if (action.action === AuthActions.SignOutSuccess) {
          this.$router.push('/landing');
        }
      }),
      Auth.Instance.user$.subscribe((user) => {
        this.user = JSON.stringify(user);
      })
    );
  },
  beforeUnmount() {
    this.subs.forEach(sub => sub.unsubscribe());
  },
  components: {
    TheLoader,
    PushNotificationInit,
    AppFooter,
    AppToolbar,
    ProductCard,
    IonContent,
    IonHeader,
    IonPage,
    IonRow,
    IonCol,
    IonGrid
  }
});
</script>

<style lang="scss" scoped>
.logo-wrapper {
  max-width: 300px;
}
.logo {
  max-height: 200px;
  max-width: 100%;
  object-fit: contain;
  object-position: left;
}
.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
