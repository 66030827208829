import { ref, onMounted, onUnmounted } from 'vue';

export function useOrientation() {
    const orientation = ref<string>(window.screen.orientation.type);

    const updateOrientation = () => {
        orientation.value = window.screen.orientation.type;
    };

    onMounted(() => {
        window.screen.orientation.addEventListener('change', updateOrientation);
    });

    onUnmounted(() => {
        window.screen.orientation.removeEventListener('change', updateOrientation);
    });

    return { orientation };
}
