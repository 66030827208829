import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { style: {"align-items":"center","justify-content":"center"} }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("svg", {
        width: "100",
        height: "30",
        viewBox: "0 0 120 30",
        xmlns: "http://www.w3.org/2000/svg",
        fill: "#999"
      }, [
        _createElementVNode("circle", {
          cx: "15",
          cy: "15",
          r: "15"
        }, [
          _createElementVNode("animate", {
            attributeName: "r",
            from: "15",
            to: "15",
            begin: "0s",
            dur: "0.8s",
            values: "15;9;15",
            calcMode: "linear",
            repeatCount: "indefinite"
          }),
          _createElementVNode("animate", {
            attributeName: "fill-opacity",
            from: "1",
            to: "1",
            begin: "0s",
            dur: "0.8s",
            values: "1;.5;1",
            calcMode: "linear",
            repeatCount: "indefinite"
          })
        ]),
        _createElementVNode("circle", {
          cx: "60",
          cy: "15",
          r: "9",
          "fill-opacity": "0.3"
        }, [
          _createElementVNode("animate", {
            attributeName: "r",
            from: "9",
            to: "9",
            begin: "0s",
            dur: "0.8s",
            values: "9;15;9",
            calcMode: "linear",
            repeatCount: "indefinite"
          }),
          _createElementVNode("animate", {
            attributeName: "fill-opacity",
            from: "0.5",
            to: "0.5",
            begin: "0s",
            dur: "0.8s",
            values: ".5;1;.5",
            calcMode: "linear",
            repeatCount: "indefinite"
          })
        ]),
        _createElementVNode("circle", {
          cx: "105",
          cy: "15",
          r: "15"
        }, [
          _createElementVNode("animate", {
            attributeName: "r",
            from: "15",
            to: "15",
            begin: "0s",
            dur: "0.8s",
            values: "15;9;15",
            calcMode: "linear",
            repeatCount: "indefinite"
          }),
          _createElementVNode("animate", {
            attributeName: "fill-opacity",
            from: "1",
            to: "1",
            begin: "0s",
            dur: "0.8s",
            values: "1;.5;1",
            calcMode: "linear",
            repeatCount: "indefinite"
          })
        ])
      ], -1),
      _createElementVNode("p", null, "Abmelden...", -1),
      _createElementVNode("a", { href: "/" }, "Startseite", -1)
    ])),
    _: 1
  }))
}