<template>
  <ion-menu content-id="main-content" side="end">
    <ion-content>
      <div class="padding: 10px 20px;" style="margin-top: env(safe-area-inset-top)">
        <app-logo/>
      </div>
      <ion-list lines="none">
        <template v-if="splitPaneVisible">
          <user-info icon v-if="isAuthenticated" />
          <apps-button />
          <settings-button />
          <help-button />
          <logout-button icon v-if="isAuthenticated" />
          <login-button v-else />
        </template>

        <template v-else>
          <ion-menu-toggle>
            <user-info icon v-if="isAuthenticated" />
          </ion-menu-toggle>
          <ion-menu-toggle>
            <apps-button />
          </ion-menu-toggle>
          <ion-menu-toggle>
            <settings-button />
          </ion-menu-toggle>
          <ion-menu-toggle>
            <help-button />
          </ion-menu-toggle>
          <ion-menu-toggle>
            <logout-button icon v-if="isAuthenticated" />
            <login-button v-else />
          </ion-menu-toggle>
        </template>
      </ion-list>
      <ion-text id="app-version">CAMPUS {{appVersion}}</ion-text>
    </ion-content>
  </ion-menu>
</template>

<script>
import { defineComponent, computed } from 'vue';
import {useStore} from "vuex";
import {
  IonMenu,
  IonMenuToggle,
  IonContent,
  IonList,
  IonText,
} from "@ionic/vue";

import LoginButton from "@/components/list-items/LoginButton";
import LogoutButton from "@/components/list-items/LogoutButton";
import SettingsButton from "@/components/list-items/SettingsButton";
import HelpButton from "@/components/list-items/HelpButton.vue";
import UserInfo from "@/components/list-items/UserInfo";
import AppsButton from "@/components/list-items/AppsButton";
import AppLogo from "@/components/app-structure/AppLogo.vue";

export default defineComponent({
  name: 'AppMenu',
  props: {
    splitPaneVisible: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const store = useStore();
    const appVersion = '1.3'

    return {
      isAuthenticated: computed(() => store.state.isAuthenticated),
      appVersion
    }
  },
  components: {
    AppsButton,
    UserInfo,
    SettingsButton,
    IonMenu,
    IonMenuToggle,
    IonContent,
    IonList,
    IonText,
    LoginButton,
    LogoutButton,
    AppLogo,
    HelpButton
  }
});
</script>

<style lang="scss" scoped>
.list-md {
  background: none !important;
}
ion-content::part(background) {
  background-image: url('@/assets/png/drawer_bg.png') !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  background-size: 100%;

  @media (prefers-color-scheme: dark) {
    background-color: #363636;
  }
}
#app-version {
  position: absolute;
  bottom: 5px;
  right: 10px;

  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-size: 12px;
  color: #aaa;
}
</style>
