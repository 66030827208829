<template>
  <div class="product-card">
    <div class="header">
      <div class="image" :style="{ 'background': color }">
        <ion-img :src="isLocked ? lockedImage : image" style="width: 100%" />
      </div>
      <div class="headings" @click="action">
        <h1>{{ title }}</h1>
        <h2>{{ subtitle }}</h2>
      </div>
    </div>
    <div class="bottom">
      <div class="body">
        <div class="left" :style="{ 'background': `${color}80` }">
          <div class="expander">
            <ion-button fill="clear" color="dark" size="small" class="no-ripple" @click="expandedText = !expandedText">
              <ion-icon class="expand-icon" slot="icon-only" :icon="expandedText ? chevronUp : chevronDown" />
            </ion-button>
          </div>
        </div>
        <div class="content">
          <slide-up-down style="width: 100%;" v-model="expandedText" :duration="200">
            <div class="text">
              <div class="ion-margin-bottom">
                <ion-img v-if="descriptionImage" :src="formattedDescriptionImage" style="max-height: 300px;max-width: 300px;" :alt="descriptionImageAlt"/>
                <slot v-else name="description-image"/>
              </div>

              <p v-if="text">{{ text }}</p>

              <slot v-else name="text"/>
            </div>
          </slide-up-down>

          <div class="actions">
            <ion-button fill="clear" color="dark" size="small" v-if="isLocked" @click="action">
              <ion-icon class="action-icon" slot="icon-only" style="height: 1.3rem;width: 1.3rem;" :icon="key" />
            </ion-button>
            <template v-else>
              <ion-button fill="clear" color="dark" size="small" v-if="phone" @click="callSchool">
                <ion-icon class="action-icon" slot="icon-only" :icon="call" />
              </ion-button>
              <ion-button fill="clear" color="dark" size="small" v-if="email" @click="emailSchool">
                <ion-icon class="action-icon" slot="icon-only" :icon="logoMail" />
              </ion-button>
              <ion-button fill="clear" color="dark" size="small" v-if="website" @click="openUrl(website)">
                <ion-icon class="action-icon" slot="icon-only" :icon="globe" />
              </ion-button>
              <ion-button fill="clear" color="dark" size="small" v-if="facebook" @click="openUrl(facebook)">
                <ion-icon class="action-icon" slot="icon-only" :icon="logoFacebook" />
              </ion-button>
              <ion-button fill="clear" color="dark" size="small" v-if="instagram" @click="openUrl(instagram)">
                <ion-icon class="action-icon" slot="icon-only" :icon="logoInstagram" />
              </ion-button>
              <ion-button fill="clear" color="dark" size="small" v-if="tiktok" @click="openUrl(tiktok)">
                <ion-icon class="action-icon" slot="icon-only" :icon="logoTiktok" />
              </ion-button>
              <ion-button fill="clear" color="dark" size="small" v-if="youtube" @click="openUrl(youtube)">
                <ion-icon class="action-icon" slot="icon-only" :icon="logoYoutube" />
              </ion-button>
              <ion-button fill="clear" color="dark" size="small" v-if="snapchat" @click="openUrl(snapchat)">
                <ion-icon class="action-icon" slot="icon-only" :icon="logoSnapchat" />
              </ion-button>
              <ion-button fill="clear" color="dark" size="small" v-if="hasSettings" :router-link="settingsUrl">
                <ion-icon class="action-icon" slot="icon-only" :icon="settings" />
              </ion-button>
              <ion-button fill="clear" color="dark" size="small" @click="action" v-if="hasMainAction">
                <ion-icon class="action-icon" slot="icon-only" :icon="play" />
              </ion-button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue'
import {
  IonButton,
  IonImg,
  IonIcon
} from '@ionic/vue'
import {
  lockClosed,
  chevronUp,
  chevronDown,
  settings,
  play,
  keySharp,
  logoYoutube,
  logoSnapchat,
  logoTiktok,
  logoFacebook,
  logoInstagram,
  mail,
  globe,
  call
} from 'ionicons/icons'

import { CallNumber } from "@awesome-cordova-plugins/call-number";
import { EmailComposer } from "@awesome-cordova-plugins/email-composer";
import { isPlatform } from '@ionic/vue';

export default defineComponent({
    setup(props) {
      const callSchool = () => {
        if (isPlatform('capacitor')) {
          CallNumber.callNumber(props.phone, true)
        } else {
          window.open(`tel:${props.phone}`, '_self')
        }
      }

      const emailSchool = () => {
          if (isPlatform('capacitor')) {
            EmailComposer.open({ to: props.email, isHtml: true });
          } else {
            window.open(`mailto:${props.email}`, '_self')
          }
      }

      const lockedImage = computed(() => {
        return new URL('@/assets/png/lock.png', import.meta.url).href;
      })

      const openUrl = (url: string) => {
        if (!url.match(/^https?:\/\//i)) url = 'http://' + url;
        window.open(url, '_blank')
      }
      const isValidBase64 = (str: string) => /^[A-Za-z0-9+/]+={0,2}$/.test(str);
      const formattedDescriptionImage = computed(() => isValidBase64(props.descriptionImage) ? `data:image/png;base64,${props.descriptionImage}` : props.descriptionImage);

      return {
        lockedImage,
        lockClosed,
        chevronUp,
        chevronDown,
        settings,
        play,
        key: keySharp,
        logoYoutube,
        logoSnapchat,
        logoTiktok,
        logoFacebook,
        logoInstagram,
        globe,
        logoMail: mail,
        call,
        callSchool,
        emailSchool,
        openUrl,
        formattedDescriptionImage
      }
    },
    props: {
      color: String,
      image: String,
      descriptionImage: {
        type: String,
        default: null
      },
      descriptionImageAlt: {
        type: String,
        default: ''
      },
      title: String,
      subtitle: String,
      isLocked: Boolean,
      text: String,
      action: Function,
      hasMainAction: {
        type: Boolean,
        default: true
      },
      hasSettings: {
        type: Boolean,
        default: false
      },
      settingsUrl: {
        type: String,
        default: "/preferences"
      },
      email: {
        type: String,
        default: null
      },
      phone: {
        type: String,
        default: null
      },
      website: {
        type: String,
        default: null
      },
      instagram: {
        type: String,
        default: null
      },
      facebook: {
        type: String,
        default: null
      },
      tiktok: {
        type: String,
        default: null
      },
      snapchat: {
        type: String,
        default: null
      },
      youtube: {
        type: String,
        default: null
      }
    },
    components: {
      IonButton,
      IonImg,
      IonIcon
    },
  data () {
      return {
        expandedText: false
      }
  }
})
</script>

<style scoped lang="scss">
  $border-radius: 5px;

  .product-card {
    border-radius: $border-radius;
    margin: 10px;
    overflow: hidden;
    color: #555555;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    .header {
      display: flex;
      flex-direction: row;

      .image {
        width: 84px;
        padding: 10px;
        flex-shrink: 0;
      }

      .headings {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 15px;

        h1, h2 {
          margin: 0;
          padding: 0;
        }
        h1 {
          text-transform: uppercase;
          font-size: 18px;
        }
        h2 {
          font-size: 14px;
        }
      }
    }
    .bottom {
      box-shadow: inset 0 3px 5px #eee;

      .body {
        display: flex;
        .left {
          display: flex;
          align-items: flex-end;
          flex-shrink: 0;
          width: 84px;
          padding: 5px 0;

          .expander {
            width: 84px;
            display: flex;
            justify-content: center;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: 16px;
          flex-grow: 1;

          .text {
            padding: 15px;
            font-size: 14px;
            width: 100%;
          }

          .actions {
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
            padding: 5px 0;

          }
        }
      }
    }
  }

  @media (prefers-color-scheme: dark) {
    .product-card {
      background: #363636;
      color: white;
      .bottom {
        box-shadow: inset 0 3px 5px #222;
      }
    }
  }

  @media (prefers-color-scheme: light) {
    .product-card {
      background: #ffffff;
    }
  }

  .no-ripple {
    --ripple-color: transparent;
  }

  .action-icon, .expand-icon {
    height: 1.5rem;
    width: 1.5rem;
  }

  .action-icon {
    padding: 0 12px;
  }
</style>
