import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    button: "",
    "router-link": "/",
    "router-direction": "back"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_icon, {
        slot: "start",
        ios: _ctx.apps,
        md: _ctx.apps
      }, null, 8, ["ios", "md"]),
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Apps")
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}