import { createRouter, createWebHistory } from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import HomePage from '../views/HomePage.vue'
import LandingPage from '../views/LandingPage.vue'
import PreferencesPage from '../views/PreferencesPage.vue'
import StartRedirect from '../views/StartRedirect.vue'
import EndRedirect from '../views/EndRedirect.vue'
import { Auth } from '@/services/AuthService';
import { filter, switchMap, take } from 'rxjs/operators';
import CampusAppPage from '@/views/CampusAppPage.vue'
import ClickAndLearnAppPage from '@/views/ClickAndLearnAppPage.vue'
import AccountPage from "@/views/AccountPage.vue";
import CampusCentralSettingsPage from "@/views/CampusCentralSettingsPage.vue"
import ClickAndLearnSettingsPage from "@/views/ClickAndLearnSettingsPage.vue"
import ELearningAppPage from "@/views/ELearningAppPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/landing',
    name: 'Landing',
    component: LandingPage,
    meta: { requiresAuth: false, layout: 'layout-guest' }
  },
  {
    path: '/preferences',
    name: 'Preferences',
    component: PreferencesPage,
    meta: { layout: 'layout-guest' }
  },
  {
    path: '/preferences/account',
    name: 'Account',
    component: AccountPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/preferences/campus-central',
    name: 'CampusCentralSettings',
    component: CampusCentralSettingsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/preferences/click-and-learn',
    name: 'ClickAndLearnSettings',
    component: ClickAndLearnSettingsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/authcallback',
    name: 'Redirect',
    component: StartRedirect
  },
  {
    path: '/endsession',
    name: 'EndRedirect',
    component: EndRedirect
  },
  {
    path: '/campus-app',
    name: 'CampusApp',
    component: CampusAppPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/click-and-learn-app',
    name: 'ClickAndLearnApp',
    component: ClickAndLearnAppPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/e-learning-app',
    name: 'ELearningAppPage',
    component: ELearningAppPage,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    Auth.Instance.initComplete$.pipe(
      filter(complete => complete),
      switchMap(() => Auth.Instance.isAuthenticated$),
      take(1))
        .subscribe((isAuthenticated) => {
          if (isAuthenticated) {
            next()
          } else {
            Auth.tryRefreshingTheToken()
              .then(() => Auth.Instance.loadUserInfo().then(() => next()))
              .catch(error => {
                Auth.clearUserData()

                // eslint-disable-next-line no-console
                console.error(error)
                next('/landing')
              })
          }
        });
  }else{
    next();
  }
})

export default router
