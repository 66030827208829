import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "product-card" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "bottom" }
const _hoisted_4 = { class: "body" }
const _hoisted_5 = { class: "expander" }
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "text" }
const _hoisted_8 = { class: "ion-margin-bottom" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_slide_up_down = _resolveComponent("slide-up-down")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "image",
        style: _normalizeStyle({ 'background': _ctx.color })
      }, [
        _createVNode(_component_ion_img, {
          src: _ctx.isLocked ? _ctx.lockedImage : _ctx.image,
          style: {"width":"100%"}
        }, null, 8, ["src"])
      ], 4),
      _createElementVNode("div", {
        class: "headings",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)))
      }, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("h2", null, _toDisplayString(_ctx.subtitle), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "left",
          style: _normalizeStyle({ 'background': `${_ctx.color}80` })
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ion_button, {
              fill: "clear",
              color: "dark",
              size: "small",
              class: "no-ripple",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expandedText = !_ctx.expandedText))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  class: "expand-icon",
                  slot: "icon-only",
                  icon: _ctx.expandedText ? _ctx.chevronUp : _ctx.chevronDown
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ])
        ], 4),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_slide_up_down, {
            style: {"width":"100%"},
            modelValue: _ctx.expandedText,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.expandedText) = $event)),
            duration: 200
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (_ctx.descriptionImage)
                    ? (_openBlock(), _createBlock(_component_ion_img, {
                        key: 0,
                        src: _ctx.formattedDescriptionImage,
                        style: {"max-height":"300px","max-width":"300px"},
                        alt: _ctx.descriptionImageAlt
                      }, null, 8, ["src", "alt"]))
                    : _renderSlot(_ctx.$slots, "description-image", { key: 1 }, undefined, true)
                ]),
                (_ctx.text)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.text), 1))
                  : _renderSlot(_ctx.$slots, "text", { key: 1 }, undefined, true)
              ])
            ]),
            _: 3
          }, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_10, [
            (_ctx.isLocked)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  fill: "clear",
                  color: "dark",
                  size: "small",
                  onClick: _ctx.action
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      class: "action-icon",
                      slot: "icon-only",
                      style: {"height":"1.3rem","width":"1.3rem"},
                      icon: _ctx.key
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.phone)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        fill: "clear",
                        color: "dark",
                        size: "small",
                        onClick: _ctx.callSchool
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: "action-icon",
                            slot: "icon-only",
                            icon: _ctx.call
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.email)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        fill: "clear",
                        color: "dark",
                        size: "small",
                        onClick: _ctx.emailSchool
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: "action-icon",
                            slot: "icon-only",
                            icon: _ctx.logoMail
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.website)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 2,
                        fill: "clear",
                        color: "dark",
                        size: "small",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openUrl(_ctx.website)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: "action-icon",
                            slot: "icon-only",
                            icon: _ctx.globe
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.facebook)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 3,
                        fill: "clear",
                        color: "dark",
                        size: "small",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openUrl(_ctx.facebook)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: "action-icon",
                            slot: "icon-only",
                            icon: _ctx.logoFacebook
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.instagram)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 4,
                        fill: "clear",
                        color: "dark",
                        size: "small",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openUrl(_ctx.instagram)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: "action-icon",
                            slot: "icon-only",
                            icon: _ctx.logoInstagram
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.tiktok)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 5,
                        fill: "clear",
                        color: "dark",
                        size: "small",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openUrl(_ctx.tiktok)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: "action-icon",
                            slot: "icon-only",
                            icon: _ctx.logoTiktok
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.youtube)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 6,
                        fill: "clear",
                        color: "dark",
                        size: "small",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openUrl(_ctx.youtube)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: "action-icon",
                            slot: "icon-only",
                            icon: _ctx.logoYoutube
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.snapchat)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 7,
                        fill: "clear",
                        color: "dark",
                        size: "small",
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.openUrl(_ctx.snapchat)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: "action-icon",
                            slot: "icon-only",
                            icon: _ctx.logoSnapchat
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.hasSettings)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 8,
                        fill: "clear",
                        color: "dark",
                        size: "small",
                        "router-link": _ctx.settingsUrl
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: "action-icon",
                            slot: "icon-only",
                            icon: _ctx.settings
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["router-link"]))
                    : _createCommentVNode("", true),
                  (_ctx.hasMainAction)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 9,
                        fill: "clear",
                        color: "dark",
                        size: "small",
                        onClick: _ctx.action
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: "action-icon",
                            slot: "icon-only",
                            icon: _ctx.play
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ], 64))
          ])
        ])
      ])
    ])
  ]))
}