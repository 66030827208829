<template>
  <ion-item button lines="none" @click="showHelp">
    <ion-icon slot="start" :ios="informationCircleSharp" :md="informationCircleSharp" />
    <ion-label>{{ $t('interface.help') }}</ion-label>
  </ion-item>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import { informationCircleSharp } from 'ionicons/icons';
import {IonIcon, IonItem, IonLabel} from "@ionic/vue";
import store from "@/store";

export default defineComponent({
  name: "SettingsButton",
  setup() {
    return { informationCircleSharp }
  },
  components: {
    IonItem,
    IonIcon,
    IonLabel
  },
  methods: {
    showHelp() {
      store.commit('setHelpModalShow', true)
    }
  }
})
</script>

<style
    scoped
    lang="scss">
ion-item {
  --background: transparent !important;
}
</style>
